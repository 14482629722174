















import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsTabs from '@/components/VsTabs/Index.vue'

@Component({
    name: 'SendersIndex',
    components: {
        VsContainer,
        VsTabs,
    },
})
export default class extends Vue {
    get activeTab () {
        return this.$route.name
    }

    get tabs () {
        const tabs = [
            {
                label: this.$t('senders.tabs.sendersEmail'),
                id: 'sendersEmail',
                to: {
                    name: 'sendersEmail',
                },
            },
            {
                label: this.$t('senders.tabs.sendersSms'),
                id: 'sendersSms',
                to: {
                    name: 'sendersSms',
                },
            },
        ]

        return tabs
    }
}
